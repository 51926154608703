<template>
  <v-container>
    <h1> <textloader-component textKey="EngageradeRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="EngageradeBrödtext"/> </paragraph-component>

    <v-list>
      <v-list-group v-for="(term, i) in engageradeList" :key="i">
        <template v-slot:activator>
          <v-list-item-title>{{term.year}}</v-list-item-title>
        </template>
        <v-list-group
          no-action
          sub-group
          v-for="(utskott, j) in term.utskott"
          :key="j"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{utskott.namn}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(member, k) in utskott.medlemmar" :key="k">
            <v-list-item-title :v-text="member">{{member}}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </v-list>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => {
    return {
      engageradeList: []
    }
  },
  created: function () {
    this.engageradeList = require('@/assets/engagerade.json')
  }
}
</script>
